import styled from "styled-components";
import arrowIcon from "../../../images/icon-dropdown-arrow.svg";
import { sizes } from "../../../helpers/MediaQueries";

export const ContactSection = styled.div`
  padding: 40px;
  background: #c9cfc7;
  @media (min-width: ${sizes.desktopSmall}px) {
    padding: 80px 100px;
  }

  @media (max-width: ${sizes.phoneXL}px) {
    padding: 40px 16px;
  }

  .ao-form {
    .ao-recaptcha-error {
      padding: 0;
    }

    input[type="checkbox"],
    input[type="radio"] {
      display: none;
    }

    input[type="checkbox"] + span:before,
    input[type="radio"] + span:before {
      content: "";
      border: 1px solid #434b42;
      width: 22px;
      height: 22px;
      display: block;
      transition: all 0.5s ease-in-out;
      margin-right: 10px;
      box-shadow: inset 0 0 0 1px #434b42;
      flex: 0 0 22px;
      position: absolute;
      left: 0;
    }

    input[type="checkbox"] + span,
    input[type="radio"] + span {
      display: block;
      font: normal normal bold 18px/24px Optima;
      letter-spacing: 4px;
      padding-left: 30px;
      position: relative;
      cursor: pointer;
      color: #434b42;

      @media (max-width: ${sizes.desktop}px) {
        font: normal normal bold 16px/24px Optima;
        letter-spacing: 2px;
      }
    }

    input[type="checkbox"]:checked + span:before,
    input[type="radio"]:checked + span:before {
      box-shadow: inset 0 0 0 6px #c9cfc7;
      background: #434b42;
    }

    input:not([type="radio"]):not([type="checkbox"]) {
      width: 100%;
      display: block;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 5px;
    }

    .ao-form-label,
    .ao-combo-label {
      display: block;
      text-align: left;
      font: normal normal bold 18px/24px Optima;
      letter-spacing: 4px;
      color: #535d53;
      text-transform: uppercase;
      white-space: nowrap;
      user-select: none;

      @media (max-width: ${sizes.desktop}px) {
        font: normal normal bold 16px/24px Optima;
        letter-spacing: 2px;
      }
      @media (max-width: ${sizes.tablet}px) {
        letter-spacing: 4px;
      }
      @media (max-width: ${sizes.phoneXL}px) {
        white-space: unset;
      }
    }

    .ao-form-label {
      margin-bottom: 8px;
      @media (max-width: ${sizes.tablet}px) {
        margin-bottom: 0;
      }
    }

    .ao-column-inner {
      flex: auto;
      width: 100%;
    }

    .ao-column-4 .ao-column-inner:nth-child(1) {
      padding: 0 50px 0 0;

      @media (max-width: ${sizes.tablet}px) {
        padding: 0;
      }
    }

    .ao-input-block,
    .ao-combo-block {
      max-width: 400px;
      position: relative;

      @media (max-width: ${sizes.tablet}px) {
        max-width: none;
      }

      .ao-form-error-message {
        display: block;
        clear: both;
        line-height: 1.5;
        color: red;
        text-align: right;
        letter-spacing: 1px;
        margin-top: 4px;
        font: 16px / 20px Optima !important;
      }
    }

    @media (max-width: 1500px) {
      .ao-block-wrapper {
        padding: 0 !important;
      }
    }

    .ao-form-field {
      background: #889287;
      border: none;
      border-radius: 0 !important;
      padding: 20px !important;
      font: normal normal 100 20px / 26px Optima !important;
      letter-spacing: 2px;
      color: white;

      &:focus {
        background: #434b42;
      }

      @media (max-width: ${sizes.desktopSmall}px) {
        font: normal normal 100 18px / 24px Optima !important;
        padding: 16px !important;
      }
      @media (max-width: ${sizes.tablet}px) {
        font: normal normal 100 16px/22px Optima;
      }
    }

    select.ao-form-field {
      cursor: pointer;
        width: 100%;
        display: block;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-user-select: none;
    }

    .ao-column.ao-column-4 {
      max-width: 450px;
      width: 33.3333%;
      display: flex;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      @media (max-width: ${sizes.desktopSmall}px) {
        max-width: none;
      }
    }

    .ao-column.ao-column-4:nth-child(3) {
      margin-left: auto;
    }

    .ao-column.ao-column-4:nth-child(2) {
      .ao-combo-block:after {
        cursor: pointer;
        content: "";
        position: absolute;
        width: 16px;
        height: 10px;
        background: url(${arrowIcon}) 50% 50%;
        right: 24px;
        top: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
        pointer-events: none;
      }
    }

    .ao-column.ao-column-12 {
      width: 100%;
    }

    .ao-row {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      position: relative;
      display: flex;
    }

    .ao-row:nth-child(1) {
      @media (max-width: ${sizes.desktopSmall}px) {
        display: flex;
        flex-wrap: wrap;

        .ao-column.ao-column-4 {
          width: 50% !important;
        }

        .ao-column.ao-column-4:nth-child(3) {
          width: 100% !important;
          margin: 0;

          .ao-column-inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 0;

            > .ao-block-wrapper {
              width: 50%;
            }
          }
        }
      }

      @media (max-width: ${sizes.tablet}px) {
        .ao-column.ao-column-4 {
          width: 100% !important;

          &:nth-child(2) {
            margin-top: 16px;
            max-width: none;
            @media (max-width: ${sizes.tablet}px) {
              margin-top: 0;
            }

            .ao-column-inner {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              > .ao-block-wrapper:nth-child(1),
              > .ao-block-wrapper:nth-child(2) {
                width: calc(50% - 12px);

                @media (max-width: ${sizes.phoneXL}px) {
                  width: 100%;
                }
              }

              > .ao-block-wrapper:nth-child(3) {
                width: 100%;
              }
            }
          }
        }

        .ao-column-inner {
          padding: 0 !important;
        }

        .ao-column.ao-column-4:nth-child(3) {
          .ao-column-inner {
            flex-direction: column;

            > .ao-block-wrapper {
              width: 100%;
              margin-top: 0;
            }
          }
        }
      }

      .ao-column:nth-child(3) .ao-column-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .ao-combo-block {
          .ao-form-label {
            margin-bottom: 24px;
            @media (max-width: ${sizes.tablet}px) {
              margin-bottom: 14px;
            }
          }

          .ao-combo-label {
            display: flex;
            align-items: center;
            margin-right: 35px;

            input {
              margin: 0 8px 0 0;
            }
          }

          &:nth-child(1) .ao-combo-layout {
            display: flex;
          }
        }

        .ao-block-wrapper:nth-child(2) .ao-combo-layout {
          display: flex;
          flex-direction: column;

          .ao-combo-label {
            margin-bottom: 24px;
          }
        }

        .ao-combo-layout {
          outline: none;
        }
      }
    }

    .ao-submit-block {
      margin-top: 60px;

      @media (max-width: ${sizes.desktopSmall}px) {
        margin-top: 30px;
      }
      @media (max-width: ${sizes.tablet}px) {
        margin-top: 0;
      }

      > div {
        text-align: left !important;
      }

      button.ao-form-submit {
        font: normal normal bold 28px/28px Optima !important;
        letter-spacing: 7.39px !important;
        text-transform: uppercase;
        transition: 0.2s;
        outline: none;
        padding: 20px 0;
        background: #c9cfc7;
        border: 2px solid #889287 !important;
        color: #535d53 !important;
        width: 29%;
        max-width: 400px;
        &:hover {
          background: #889287;
          color: white !important;
        }

        @media (max-width: ${sizes.desktop}px) {
          font: normal normal bold 20px/28px Optima !important;
          letter-spacing: 2px !important;
        }
        @media (max-width: ${sizes.tablet}px) {
          font: normal normal bold 20px/28px Optima !important;
          width: 100%;
          max-width: none;
        }
      }
    }

    .ao-form-error-message {
      text-align: right;
      font: 16px / 20px Optima !important;
      letter-spacing: 1px;
      margin-top: 4px;
    }

    .ao-richtext-block {
      max-width: 1200px;
      margin: 0 auto;

      p {
        margin: 0;
      }

      p:nth-child(1) {
        margin-bottom: 40px;
      }

      p:nth-child(2) {
        font: normal normal 100 24px/41px Optima;
        letter-spacing: 0;
        color: #434b42;
        margin-bottom: 50px;
        @media (max-width: ${sizes.tablet}px) {
          font: normal normal 100 18px/30px Optima;
        }
        @media (max-width: ${sizes.phoneXL}px) {
          font: normal normal 100 16px/20px Optima;
        }
      }
    }
  }
`;

export const ContactHeader = styled.h2`
  font: normal normal 100 37px/48px Optima;
  letter-spacing: 9.25px;
  color: #535d53;
  text-transform: uppercase;
  margin-bottom: 72px;
  margin-top: 8px;
  user-select: none;

  @media (max-width: ${sizes.desktop}px) {
    font: normal normal 100 36px/44px Optima;
  }
  @media (max-width: ${sizes.desktopSmall}px) {
    letter-spacing: 7px;
    margin-bottom: 34px;
  }
  @media (max-width: ${sizes.tablet}px) {
    font: normal normal 100 28px/28px Optima;
    letter-spacing: 5px;
    margin-bottom: 24px;
  }
`;

export const FormSubmitedMessage = styled.div`
  font: 100 24px / 41px Optima;
  letter-spacing: 0px;
  color: rgb(67, 75, 66);
  margin-bottom: 50px;
  margin-top: 150px;
  text-align: center;
`;
import styled from "styled-components";
import { sizes } from "../../helpers/MediaQueries";

export const LegalWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: auto;
    height: 100vh;
    background: white;
    z-index: 10000;
    transition: top .5s ease-in-out;
    display: none;
    `
export const LegalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 35px;
    position: fixed;
    top: 0;
    left: 0;
    right: 15px;
    background: white;
    transition: top .5s ease-in-out;
    
    @media (max-width: ${sizes.mobile}) { 
        padding: 24px 24px 14px;
        align-items: flex-end;
    }
    `
export const LegalHeaderLogo = styled.img`
    
    @media (max-width: ${sizes.mobile}) { 
        width: 107px;
    }
    `
export const LegalHeaderButton = styled.span`
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    @media (max-width: ${sizes.mobile}) { 
        margin-bottom: 2px;
    }
    &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        background: black;
        width: 2px;
        height: 125%;
        top: 50%;
        left: 50%;
    }
    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    `
export const LegalContent = styled.div`
    max-width: 1430px;
    padding: 0 35px 80px;
    margin: 160px auto 0;
    @media (max-width: ${sizes.mobile}) { 
        padding: 0 24px 80px;
        margin: 120px auto 0;
    }
    `
export const LegalHeading = styled.h1`
    text-align: left;
    font: normal normal normal 30px/50px Optima;
    letter-spacing: 9px;
    color: #797972;
    text-transform: uppercase;
    margin: 0 0 64px;
    
    @media (max-width: ${sizes.tabletS}) { 
        font: normal normal normal 20px/40px Optima;
        letter-spacing: 6px;
        margin: 0 0 40px;
    }
    `
export const LegalContentBlock = styled.div`
    max-width: 800px;
    margin: 0 0 40px;
    `
export const LegalContentHead = styled.h2`
    text-align: left;
    font: normal normal normal 20px/20px Optima;
    letter-spacing: 6px;
    color: #000000;
    text-transform: uppercase;
    margin: 0 0 16px;
    @media (max-width: ${sizes.tabletS}) { 
        font: normal normal normal 18px/20px Optima;
        letter-spacing: 5.2px;
    }
    `
export const LegalContentText = styled.p`
    text-align: left;
    font: normal normal normal 18px/28px Optima;
    letter-spacing: 0;
    color: #797972;
    margin: 0;
    `
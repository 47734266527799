import React, { FC } from 'react';
import {
    LegalContent,
    LegalContentBlock,
    LegalContentHead,
    LegalContentText,
    LegalHeader,
    LegalHeaderButton,
    LegalHeaderLogo,
    LegalHeading,
    LegalWrapper
} from "./Legal.styled";
import logoMini from "../../images/logo-PH-small.svg";

const legalTextQueries = [
    {
        heading: 'FEATURES/FINISHES',
        text: 'Features, amenities, floor plans, elevations, square footage and designs vary per plan and are subject to changes or substitution without prior notice. Actual finishes and materials installed in homes may differ from the materials depicted on this website or in the Sales Gallery. Natural materials like stone or wood typically are inconsistent and a variety of imperfections is to be expected. These inconsistencies and imperfections are normal and to be expected.',
    },
    {
        heading: 'FLOOR PLANS',
        text: 'Plans and elevations are artist’s renderings only and may not accurately represent the actual condition of a home as constructed and may contain options or furnishings which are not standard. We reserve the right to make changes to pricing, floor plans, specifications, dimensions and elevations without prior notice. Stated dimensions and square footage are approximate and should not be used as representation of the home’s precise or actual size. Any statement, verbal or written, regarding the square footage size of any home has been estimated and should not be construed to indicate certainty. Garage, parking space and/or storage space sizes may vary from the final product and may not accommodate all vehicles.',
    },
    {
        heading: 'HUMAN MODELS/LIFESTYLE PHOTOS',
        text: 'Models/lifestyle photos do not reflect age, racial or ethnic preferences.',
    },
    {
        heading: 'INTELLECTUAL PROPERTY',
        text: 'This website and all materials contained herein, including but not limited to floor plans, images and elevations, are copyrighted and may not be distributed, modified, or reproduced in whole or in part without prior written permission from Uptown Newport Development, LLC. This website is provided as a service to Internet users and its use is a privilege to the viewer. All information is provided without warranty of any kind, either expressed or implied or for non-infringement. Access and use of the website and all company links are subject to the terms and conditions set forth herein and to all applicable laws regulating the use of the Internet and those relating to the use of information. The use of this website is also subject to all applicable laws involving copyrighted materials and intellectual property.',
    },
    {
        heading: 'MAPS AND IMAGES',
        text: 'Site plans, community maps, photos and/or computer-generated renderings have been posted on this site for illustration purposes only. All site plans, community maps, photos and computer-generated renderings shown are conceptual in nature and are merely an artist’s rendition. They may not accurately represent the actual condition of the item being represented. All photos/videos shown depict the community or home as of the date taken. All Illustrations are solely for illustrative purposes and should never be relied upon.',
    },
    {
        heading: 'PRIVACY POLICY',
        text: 'This website uses cookies to analyze and optimize your content and reading experience. You can turn off the use of cookies at any time by changing your specific browser settings. We are not responsible for republished content from this website or other websites without our permission. This privacy policy is subject to change without notice and was last updated on October 14, 2020.',
    },
]

interface IProps {
    closeModal: any,
    modalOpen: boolean
}

const Legal: FC<IProps> = ({ closeModal, modalOpen }) => {

    const renderContent = () => {
        return (
            legalTextQueries.map((el, key) => {
                return (
                    <LegalContentBlock key={key}>
                        <LegalContentHead>
                            {el.heading}
                        </LegalContentHead>
                        <LegalContentText>
                            {el.text}
                        </LegalContentText>
                    </LegalContentBlock>
                )
            })
        )
    }

    return  (
        <LegalWrapper style={{
            top: modalOpen ? 0 : '100vh'
        }}
        className={'legal-wrapper'}
        >
            <LegalHeader style={{
                top: modalOpen ? 0 : '100vh'
            }}>
                <LegalHeaderLogo src={logoMini} alt={'logo image'}/>
                <LegalHeaderButton onClick={closeModal}/>
            </LegalHeader>
            <LegalContent>
                <LegalHeading>Legal</LegalHeading>
                {renderContent()}
            </LegalContent>
        </LegalWrapper>
    )

}

export default Legal;
import React, {useContext, useEffect, useState} from "react";
import Footer from "../components/footer";
import ContactForm from "../components/contact-page/contact-form";
import ContactInfo from "../components/contact-page/contact-info";
import FirstLoadContext from "../../src/context";
import Legal from "../components/Legal/Legal";

const Contact = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setFirstLoad } = useContext(FirstLoadContext);
  useEffect(() => {
    setFirstLoad(false);
  }, [setFirstLoad]);

  const openModal = () => {
    const legalWrapper = document && document.getElementsByClassName('legal-wrapper')[0];

    legalWrapper && (legalWrapper.style.display = 'block');

    setTimeout(() => {
      setModalOpen(true);
      document.body.style.overflowY = 'hidden';
      document.documentElement.style.overflowY = 'hidden';
    }, 0);
  };
  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflowY = 'auto';
    document.documentElement.style.overflowY = 'auto';

    setTimeout(() => {
      const legalWrapper = document && document.getElementsByClassName('legal-wrapper')[0];
      legalWrapper && (legalWrapper.style.display = 'none');
    }, 500);
  };
  return (
    <>
      <ContactForm />
      <ContactInfo />
      <Footer  openModal={openModal}/>
      <Legal closeModal={closeModal} modalOpen={modalOpen}/>
    </>
  );
};

export default Contact;

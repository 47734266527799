import styled from "styled-components";
import { sizes } from "../../../helpers/MediaQueries";

export const ContactInfoSection = styled.div`
  padding: 0 40px 72px;
  background: #c9cfc7;
  @media (min-width: ${sizes.desktopSmall}px) {
    padding: 0 100px 72px;
  }

  @media (max-width: ${sizes.phoneXL}px) {
    padding: 0 16px 72px;
  }
`;

export const ContactInfoText = styled.div`
  text-align: left;
  font: normal normal 100 17px/23px Optima;
  letter-spacing: 2.55px;
  color: #535d53;
  text-transform: uppercase;
  max-width: 400px;

  &.bold-text {
    font-weight: bold;
    max-width: 450px;
  }

  @media (max-width: ${sizes.desktop}px) {
  }
  @media (max-width: ${sizes.desktopSmall}px) {
  }
  @media (max-width: ${sizes.tablet}px) {
  }
`;

import React, { FC } from "react";
import { ContactInfoSection, ContactInfoText } from "./index.styled";

const ContactInfo: FC = () => {
  return (
    <ContactInfoSection>
      <ContactInfoText className={"bold-text"}>SALES GALLERY NOW OPEN BY APPOINTMENT ONLY</ContactInfoText>
      <br />
      <ContactInfoText>
        4440 Von Karman Ave, Suite 240, Newport Beach, CA 92660
        <br />
        <br />
        949 651 6000
      </ContactInfoText>
    </ContactInfoSection>
  );
};

export default ContactInfo;

import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { ContactHeader, ContactSection, FormSubmitedMessage } from "./index.styled";
import { useForm } from "react-hook-form";

const ContactForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const contactRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [formSubmited, setFormSubmited] = useState<boolean>(false);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onSubmit = useCallback(
    (data: Object) => {
      fetch("/submit-form", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      })
        .then(() => setFormSubmited(true))
        .catch(() => setFormSubmited(true))
    }, []
  );

  useEffect(() => {
    console.log({ errors })
  }, [errors])

  return (
    <ContactSection ref={contactRef} className={"content-wrapper"}>
      {!formSubmited ? (
        <>
          <ContactHeader className={"contact-header"}>Contact</ContactHeader>
          <form className="ao-form" onSubmit={handleSubmit(onSubmit)} data-validate-blur="">
            <div className="ao-row" id="row-">
              <div className="ao-column ao-column-4 tablet-ao-column-1 mobile-ao-column-1" id="column-c1601465251448">
                <div className="ao-column-inner">
                  <div style={{ paddingBottom: '0px' }} className="ao-block-wrapper">
                    <div id="block-b1601465206152" className="ao-input-block ao-left">
                      <label className="ao-form-label">
                        First Name
                        <span className="ao-required">&nbsp;*</span>
                      </label>
                      <input id="b1601465206152" {...register("first", { required: true })} type="text" placeholder=" " data-type="text" className="ao-form-field ao-left" data-error-message="required|Required field" data-validator="required" data-uw-rm-form="nfx" />
                      <span className="ao-form-error-message">{!!errors['first'] && 'Required field'}&nbsp;</span>
                    </div>
                  </div>
                  <div style={{ paddingBottom: '0px' }} className="ao-block-wrapper">
                    <div id="block-b1601465269572" className="ao-input-block ao-left">
                      <label className="ao-form-label">
                        Last Name
                        <span className="ao-required">&nbsp;*</span>
                      </label>
                      <input id="b1601465269572" {...register("last", { required: true })} type="text" placeholder=" " data-type="text" className="ao-form-field ao-left" data-error-message="required|Required field" data-validator="required" data-uw-rm-form="nfx" />
                      <span className="ao-form-error-message">{!!errors['last'] && 'Required field'}&nbsp;</span>
                    </div>
                  </div>
                  <div className="ao-block-wrapper">
                    <div id="block-b1601465316120" className="ao-input-block ao-left">
                      <label className="ao-form-label">
                        Email
                        <span className="ao-required">&nbsp;*</span>
                      </label>
                      <input id="b1601465316120" {...register("email", { required: true })} type="text" placeholder=" " data-type="text" className="ao-form-field ao-left" data-error-message="required|Required field" data-validator="required" data-uw-rm-form="nfx" />
                      <span className="ao-form-error-message">{!!errors['email'] && 'Required field'}&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ao-column ao-column-4 tablet-ao-column-1 mobile-ao-column-1" id="column-c1601465257612">
                <div className="ao-column-inner">
                  <div style={{ paddingBottom: '0px' }} className="ao-block-wrapper">
                    <div id="block-b1601465347940" className="ao-input-block ao-left">
                      <label className="ao-form-label">
                        Mobile
                      </label>
                      <input id="b1601465347940" {...register("phone", {})} type="text" placeholder=" " data-type="text" className="ao-form-field ao-left" data-error-message="phoneus|Invalid phone number" data-validator="phoneus" data-uw-rm-form="nfx" />
                      <span className="ao-form-error-message">&nbsp;</span>
                    </div>
                  </div>
                  <div style={{ paddingBottom: '0px' }} className="ao-block-wrapper">
                    <div id="block-b1601465387150" className="ao-input-block ao-left">
                      <label className="ao-form-label">
                        ZIP/POSTAL CODE
                        <span className="ao-required">&nbsp;*</span>
                      </label>
                      <input id="b1601465387150" {...register("zip", { required: true })} type="text" placeholder=" " data-type="text" className="ao-form-field ao-left" data-error-message="required|Required field" data-validator="required" data-uw-rm-form="nfx" />
                      <span className="ao-form-error-message">{!!errors['zip'] && 'Required field'}&nbsp;</span>
                    </div>
                  </div>
                  <div className="ao-block-wrapper">
                    <div id="block-b1601465491513" className="ao-combo-block  ao-left">
                      <label className="ao-form-label">
                        How Did You Hear About Us?
                        <span className="ao-required">&nbsp;*</span>
                      </label>
                      <select className="ao-form-field" id="b1601465491513" {...register("source", { required: true })} data-validator="required" data-error-message="required|Required field" data-uw-rm-form="nfx">
                        <option value=""></option>
                        <option value="Direct Mail">Direct Mail</option>
                        <option value="Drive or Walk By">Drive or Walk By</option>
                        <option value="Email">Email</option>
                        <option value="Event">Event</option>
                        <option value="Home Search Portal">Home Search Portal</option>
                        <option value="Media Mention">Media Mention</option>
                        <option value="Online Ad">Online Ad</option>
                        <option value="Print Ad">Print Ad</option>
                        <option value="Referral">Referral</option>
                        <option value="Signage">Signage</option>
                        <option value="Brokers Tour">Brokers Tour</option>
                        <option value="Social Media">Social Media</option>
                      </select>
                      <span className="ao-form-error-message" style={{ clear: 'both' }}>{!!errors['source'] && 'Required field'}&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ao-column ao-column-4 tablet-ao-column-1 mobile-ao-column-1" id="column-c1601465258284">
                <div className="ao-column-inner">
                  <div style={{ paddingBottom: '0px' }} className="ao-block-wrapper">
                    <div id="block-b1601465769940" className="ao-combo-block  ao-left">
                      <label className="ao-form-label">
                        Are you an agent or broker?
                        <span className="ao-required">&nbsp;*</span>
                      </label>
                      <div id="b1601465769940" className="ao-combo-layout horizontal  ao-left" data-validator="required" data-error-message="required|Required field">
                        <label className="ao-combo-label ao-column-label2 ">
                          <input type="radio" {...register("broker", { required: true })} value="yes" />
                          <span>yes</span>
                        </label>
                        <label className="ao-combo-label ao-column-label2 ">
                          <input type="radio" {...register("broker", { required: true })} value="no" />
                          <span>no</span>
                        </label>
                      </div>
                      <span className="ao-form-error-message" style={{ clear: 'both' }}>{!!errors['broker'] && 'Required field'}&nbsp;</span>
                    </div>
                  </div>
                  <div style={{ paddingBottom: '0px' }} className="ao-block-wrapper">
                    <div id="block-b1601465878569" className="ao-combo-block  ao-left">
                      <label className="ao-form-label">
                        Desired home type
                        <span className="ao-required">&nbsp;*</span>
                      </label>
                      <div id="b1601465878569" className="ao-combo-layout horizontal  ao-left" data-validator="required" data-error-message="required|Required field">
                        <label className="ao-combo-label ao-column-label2 ">
                          <input type="radio" {...register("home_type", { required: true })} value="Three Bedroom" />
                          <span>Three Bedroom</span>
                        </label>
                        <label className="ao-combo-label ao-column-label2 ">
                          <input type="radio" {...register("home_type", { required: true })} value="Penthouse" />
                          <span>Penthouse</span>
                        </label>
                      </div>
                      <span className="ao-form-error-message" style={{ clear: 'both' }}>{!!errors['home_type'] && 'Required field'}&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ao-row" id="row-">
              <div className="ao-column ao-column-12 tablet-ao-column-1 mobile-ao-column-1" id="column-c1605628146783">
                <div className="ao-column-inner">
                  {/* <div style={{ paddingBottom: '0px' }} className="ao-block-wrapper">
                    <div id="block-b1605624671838" className="ao-captcha-block ao-left">
                      <div className="ao-recaptcha-wrapper">
                        <div className="ao-recaptcha-error-wrapper">
                          <div id="captcha-b1605624671838" className="g-recaptcha" data-sitekey="6LctqAsUAAAAAK3jaxOkexQ-5vz4zpy0oMlFhWOv" style="display: inline-block;"><div style="width: 304px; height: 78px;"><div><iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LctqAsUAAAAAK3jaxOkexQ-5vz4zpy0oMlFhWOv&amp;co=aHR0cHM6Ly9wYXJraG91c2VyZXNpZGVuY2VzLmNvbTo0NDM.&amp;hl=en&amp;v=O4xzMiFqEvA4YhWjk5t8Xuas&amp;size=normal&amp;cb=2bh7n25ixeju" width="304" height="78" role="presentation" name="a-gqdao0grulo8" frameborder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div><textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response" style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"></textarea></div><iframe style="display: none;" data-uw-rm-iframe="un"></iframe></div>
                        </div>
                        <div className="ao-recaptcha-error-robot-message">Please verify that you are not a robot.</div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="ao-row" id="row-">
              <div className="ao-column ao-column-12 tablet-ao-column-1 mobile-ao-column-1" id="column-c1601465599128">
                <div className="ao-column-inner">
                  <div className="ao-block-wrapper">
                    <div id="block-b1601465972583" className="ao-submit-block">
                      <div style={{ textAlign: 'left' }}>
                        <button type="submit" className="ao-form-submit"
                          style={{
                            backgroundImage: 'none',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'auto',
                            backgroundPosition: 'center center',
                            color: 'rgb(255, 255, 255)',
                            borderRadius: '0px',
                            display: 'inline-block',
                            textDecoration: 'none',
                            fontSize: '12pt',
                            fontWeight: 'normal',
                            fontStyle: 'normal',
                            borderStyle: 'solid',
                            borderColor: 'transparent',
                            borderWidth: '0px'
                          }}
                          data-uw-rm-form="submit"
                        >Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <FormSubmitedMessage>
          <b>Thank you for your interest.</b> Our Sales Manager will be in touch with you shortly.
        </FormSubmitedMessage>
      )}
    </ContactSection>
  );
};

export default ContactForm;
